import { AxiosResponse } from 'axios';
import useSWR from 'swr';

import { PlaylistPreviousPitchesAPIModel } from '@/models/Playlist';
import PlaylistAPI from '@/network/PlaylistAPI';

import useArtist from '../artist/useArtist';
import useAccountContext from '../context/useAccountContext';
import useSessionContext from '../context/useSessionContext';

const usePlaylistRecommendationsByGenre = ({ limit = 20, offset = 0 }: { limit?: number; offset?: number }) => {
  const { accountId } = useAccountContext();
  const { artist } = useArtist();
  const { sessionId, setSessionId } = useSessionContext();

  const { data, isLoading, mutate, error } = useSWR<AxiosResponse<PlaylistPreviousPitchesAPIModel> | null>(
    () => {
      if (!accountId) return null;
      return `playlist-recommendations-data-${accountId}-artistId${artist?.id}-artistGenres${artist?.details.genres.map((genre) => genre)}-limit${limit}-offset${offset}-sessionId${sessionId}`;
    },
    async () => {
      if (!accountId) return null;

      const result = await PlaylistAPI.getPlaylistRecommendationsByGenre({
        accountId,
        limit,
        offset,
        sessionId,
      });

      if (!sessionId) setSessionId(result.data.sessionId);

      return result;
    }
  );

  return {
    playlistRecommendationsByGenre: data?.data.items,
    playlistRecommendationsByGenresTotal: data?.data.itemCount,
    playlistRecommendationsByGenreIsLoading: isLoading,
    playlistRecommendationsByGenreError: error,
    sessionId: sessionId,
    refetchPlaylistRecommendationsByGenre: mutate,
  };
};

export default usePlaylistRecommendationsByGenre;
