import { useContext } from 'react';

import { SessionContext } from '@/contexts/SessionContext';

const useSessionContext = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within a SessionProvider');
  }
  return context;
};

export default useSessionContext;
